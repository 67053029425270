body {
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: "neue-kabel",sans-serif;
}
.logo, .logo-dark {
    width: 10em;
}

.App {
    max-width: 42em !important;
}

.Footer svg {
    width: 2em;
}
.menu {
    background-color: inherit;
    backdrop-filter: blur(5px);
}